<template>
  <div>
    <div class="search">
      <div class="title">活动列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="活动名称:">
          <el-input v-model="params.taskName"></el-input>
        </el-form-item>

        <!-- <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="add">添加</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column label="排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.dept"
            placeholder="请输入排序值"
            @change="setSort(row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="taskName" label="活动名称" align="center"> </el-table-column>

      <el-table-column prop="strStatus" label="状态" width="200" align="center">
      </el-table-column>

      <el-table-column prop="beginTime" label="开始时间" width="200" align="center">
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
          <!-- <el-button type="success" size="mini" @click="upData(scope.row, 1)"
            >启用</el-button
          > -->
          <el-button type="warning" size="mini" @click="upData(scope.row, 2)"
            >使失效</el-button
          >
          <el-button type="primary" size="mini" @click="upData(scope.row, 0)"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="$router.push('/taskShopList?id=' + scope.row.taskId)"
            >活动商品管理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="活动名称:" prop="taskName">
          <el-input v-model="addList.taskName" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="时间:" prop="beginTime">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  taskList,
  taskListAdd,
  taskListUpdata,
  taskListSort,
} from "../../../api/wanxiaoShop.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        taskName: "",
      },
      addList: {
        taskId: 0,
        taskName: "",
        beginTime: "",
        endTime: "",
      },
      addRules: {
        taskName: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.taskId;
    },
    setSort(row) {
      taskListSort({ taskId: row.taskId, dept: row.dept }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSelectionChange() {},
    async getList() {
      // this.params.beginTime = this.date ? this.date[0] : "";
      // this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await taskList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;

      this.date = null;
      this.getList();
    },
    add() {
      this.dialogVisible = true;
      this.addList.taskId = 0;
      this.addList.taskName = "";
    },
    edit(row) {
      this.dialogVisible = true;
      this.addList.taskId = row.taskId;
      this.addList.taskName = row.taskName;
      this.addList.beginTime = row.beginTime;
      this.addList.endTime = row.endTime;
      console.log(this.addList);

      this.$nextTick(() => {
        this.date = [new Date(row.beginTime), new Date(row.endTime)];
        console.log(this.date);
      });
    },
    upData(row, type) {
      let str = "";
      switch (type) {
        case 0:
          str = "删除";
          break;
        case 1:
          str = "启用";
          break;
        case 2:
          str = "使失效";
          break;
      }

      this.$confirm("此操作将" + str + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await taskListUpdata({
            taskId: row.taskId,
            status: type,
          });
          if (data.code == 0) {
            this.$message.success("操作成功");
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    submitForm() {
      this.addList.beginTime = this.date ? this.date[0] : "";
      this.addList.endTime = this.date ? this.date[1] : "";

      if (!this.addList.beginTime || !this.addList.endTime) {
        return this.$message.warning("请选择时间");
      }
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          if (this.load) return;
          this.load = true;
          this.addList.beginTime = this.date ? this.date[0] : "";
          this.addList.endTime = this.date ? this.date[1] : "";
          const { data } = await taskListAdd(this.addList);
          this.load = false;
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
